<template>
  
  <div class="login-layout">
    <!-- <div class="login-left"></div> -->
    <div class="login-right">
      <div class="right-head">
        <h1></h1>
        <h2></h2>
        <!-- <div class="right-head-right">
          <a href="#" @click="showContactDialog">联系我们</a>
        </div> -->
        <!-- <div class="lian-xi" :style="{display: dialogVisible ? 'block' : 'none'}">
            微信:111
        </div> -->
      </div>
      <div class="right-body">
        <div class="form">
          <div class="title">
            <h2>登陆</h2>
          </div>
          <div class="form-content">
            <form @submit.prevent="loginRequest" id="login-form">
              <div class="account">
                <input v-model="username" name="username" class="input-text" type="text" tabindex="1"
                accesskey="n" value size="25" placeholder="账号">
              </div>
              <div class="account">
                <span class="user-password"></span>
                <input v-model="password" name="password" class="input-text-pw" type="password" tabindex="1"
                accesskey="n" value size="25" placeholder="密码">
              </div>
              <div class="error" style="display: none; background: #fed;" id="username-check-tip">
              </div>
              <div class="login-button" >
                <input class="button" type="submit" accesskey="l" tabindex="4" value="登陆"> 
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="right-foot">
        <div>"不辜负你辛苦所得的每一分"</div>
        <div class="icp-info"><a href="https://beian.miit.gov.cn/" target="_blank">豫ICP备2024058134号-1</a></div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';
export default {
  data() {
    return {
      dialogVisible: false, // 控制弹窗显示与隐藏的状态变量
      username: '',
      password: '',
      errorMessage: '',
    }
  },
  computed: {
    isValid() {
      // 检查用户名和密码是否符合规定
      return this.validateUsername() && this.validatePassword();
    }
  },
  methods: {
    async loginRequest() {
      try {
        if (!this.validateUsername() || !this.validatePassword()) {
          // 如果验证不通过，弹窗提醒
          alert(this.errorMessage);
          return;
        }
        // 向后端发送登录请求
        const response = await fetch('http://127.0.0.1:8081/go-v1/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            user_count: this.username,
            pass_word: this.password,
            code: '1111'
          })
        });

         // 解析响应
        const responseData = await response.json();
        // 如果响应中包含错误信息，则显示错误信息
        if(responseData.code == 0){
          // 登录成功，跳转到新页面
          //获取token保存在本地
          localStorage.setItem('token', responseData.data.token);
          localStorage.setItem('expirationTime', responseData.data.expire);
          alert('登陆成功')
          this.$store.commit('schoolRank/resetSelectedTags');
          this.$store.commit('school/resetSelectedTags');
          this.$store.commit('school/resetSchoolsRecommend');
          this.$router.push('/school');
        }else{
          alert(responseData.msg)
        }
      } catch (error) {
        alert(error)
      }
    },

    // 点击 "联系我们" 链接时显示弹窗
    showContactDialog() {
      this.dialogVisible = !this.dialogVisible;
    },
    validateUsername() {
      // 验证用户名是否为11位手机号
      const usernameRegex = /^1[3-9]\d{9}$/;
      if (!usernameRegex.test(this.username)) {
        this.errorMessage = '用户名必须为11位手机号';
        return false;
      } else {
        this.errorMessage = '';
        return true;
      }
    },
    validatePassword() {
      // 验证密码是否在5到15位之间
      if (this.password.length < 5 || this.password.length > 15) {
        this.errorMessage = '密码长度必须在5到15位之间';
        return false;
      } else {
        this.errorMessage = '';
        return true;
      }
    },
  }
}
</script>

<style scoped>
.right-foot {
  text-align: center;
  padding: 20px 0;
}

.icp-info {
  font-size: 12px;
  color: #999;
  margin-top: 10px;
}
</style>
