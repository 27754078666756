// store/modules/userScore.js

const state = {
    userScoreInfo:{
        local:'',
        select_class:'',
        score:'',
        user_lelve:'',
        rank:'',
    },
    setUserScoreInfo:{
      user_lelve:2024,
      class_type:1,
      class_type2:3,
      score:'',
      rank:'',
    },
    fetchUserScoreInfoErrorMsg:'',
    fetchSaveUserScoreInfoErrorMsg:'',
  };
  
  const getters = {
    getUserScoreInfo: state => state.userScoreInfo
  };
  
  const mutations = {
//     updateSelectedTags(state, { tagType, tagValue }) {
//       state.selectedTags[tagType] = tagValue;
//   },
resetFetchUserScoreInfoErrorMsg(state) {
  state.fetchUserScoreInfoErrorMsg = ''
},
resetFetchSaveUserScoreInfoErrorMsg(state) {
  state.fetchSaveUserScoreInfoErrorMsg = ''
},

    resetUserScoreInfo(state){
      state.userScoreInfo.local = ''
      state.userScoreInfo.select_class = ''
      state.userScoreInfo.score = ''
      state.userScoreInfo.user_lelve = ''
      state.userScoreInfo.rank = ''
    },
    updateUserScoreInfo(state, userScoreInfo) {
        state.userScoreInfo = userScoreInfo;
        state.setUserScoreInfo.user_lelve = userScoreInfo.user_lelve
        if (userScoreInfo.select_class == '文科' ){
          state.setUserScoreInfo.class_type = 2
        }else{
          state.setUserScoreInfo.class_type = 1
        }
        state.setUserScoreInfo.score = userScoreInfo.score
        state.setUserScoreInfo.rank = userScoreInfo.rank
    },
    updateUserRank(state ,rank){
      state.setUserScoreInfo.rank = rank
    },
    updateClassType(state,type) {
      state.setUserScoreInfo.class_type = type;
    },
    exitSetUserScore(state) {
      state.setUserScoreInfo.user_lelve = state.userScoreInfo.user_lelve;
      if (state.userScoreInfo.select_class == '文科'){
        state.setUserScoreInfo.class_type = 2
      }else{
        state.setUserScoreInfo.class_type = 1
      }
      state.setUserScoreInfo.score  = state.userScoreInfo.score
      state.setUserScoreInfo.rank = state.userScoreInfo.rank
    },
  };
  
  const actions = {
    async fetchUserScoreToRank({ commit, state }) {
      try {
          const token = localStorage.getItem('token');
          if (!token) {
            alert("账号不存在或登陆失效，请重新登陆！")
            return
          }

          // 构建查询参数对象
          const params = {
            score:parseInt(state.setUserScoreInfo.score),
            type:parseInt(state.setUserScoreInfo.class_type),
          };
          const queryString = new URLSearchParams(params).toString();
          // 发起请求获取个人信息
          const response = await fetch(`http://www.henangaokao.love/go-v1/prediction/score-to-rank?${queryString}`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'token': token,
            },
          });
          const responseData = await response.json();
          if (responseData.code == 0){
            if (responseData.data != null ){
              if (responseData.data.rank != 0) {
                commit('updateUserRank', responseData.data.rank);
              }
            }
          }
        } catch (error) {
          alert('请求出错，请稍后重试！')
        }
  },

    async fetchUserScoreInfo({ commit, state }) {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
              alert("账号不存在或登陆失效，请重新登陆！")
              return
            }
            // 发起请求获取个人信息
            const response = await fetch('http://www.henangaokao.love/go-v1/my-score',{
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token'),
              },
            });
            const responseData = await response.json();
            if (responseData.code == 0){
              // 更新 userInfo 数据
              if (responseData.data != null ){
                commit('updateUserScoreInfo', responseData.data);
              }else{
                commit('resetUserScoreInfo');
              }
            }else{
              alert(responseData.msg)
              // state.fetchUserScoreInfoErrorMsg = responseData.msg
            }
          } catch (error) {
            alert('请求出错，请稍后重试！')
            // state.fetchSchoolsErrorMsg = '请求出错，请稍后重试！'
          }
    },
    async fetchSaveUserScoreInfo({ commit, state }) {
      try {
          const token = localStorage.getItem('token');
          if (!token) {
            alert("账号不存在或登陆失效，请重新登陆！")
            return
          }
                // 构建查询参数对象
          const params = {
            score:parseInt(state.setUserScoreInfo.score),
            rank:parseInt(state.setUserScoreInfo.rank),
            type:parseInt(state.setUserScoreInfo.class_type),
            year:parseInt(state.setUserScoreInfo.user_lelve)
          };
          const requestBody = JSON.stringify(params)
          const response = await fetch(`http://www.henangaokao.love/go-v1/save-score?`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'token': localStorage.getItem('token'),
            },
            body:requestBody,
          });
          const responseData = await response.json();
          if (responseData.code == 0){
            if (params.type == 1){
              state.userScoreInfo.select_class = '理科'
            }else{
              state.userScoreInfo.select_class = '文科'
            }
            state.userScoreInfo.score = params.score

          }else{
            alert(responseData.msg)

            // state.fetchSaveUserScoreInfoErrorMsg = responseData.msg
          }
        } catch (error) {
          alert('请求出错，请稍后重试！')
          // state.fetchSchoolsErrorMsg = '请求出错，请稍后重试！'
        }
    },
  };

  
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  };
  