// store/modules/schoolRank.js

const state = {
    schoolRank:{
        selectedTags:{
            name:'',
            rank_type:1,
        },
        schoolRankInfos : [],
        count:0,
        currentPage: 1 ,// 当前页码
        totalPages:1,
        pageSize: 20, // 每页显示的数量
        maxVisiblePages: 6, // 最大可见页数
    },
    fetchSchoolRanksErroMsg:'',
};
  
  const getters = {

  };
  
  const mutations = {
    resetFetchSchoolRanksErroMsg(state) {
      state.fetchSchoolRanksErroMsg = ''
    },

    resetSelectedTags(state){
      state.schoolRank.selectedTags.name = ''
      state.schoolRank.selectedTags.rank_type = 1
      state.schoolRank.schoolRankInfos = []
      state.schoolRank.count=0
      state.schoolRank.currentPage=1
      state.schoolRank.totalPages=1
    },

    updateSchools(state, schools) {
        state.schoolRank.schoolRankInfos = schools;
    },    
    updateRankType(state, rank_type) {
      state.schoolRank.selectedTags.rank_type = rank_type;
      state.schoolRank.selectedTags.name = '';
      state.schoolRank.currentPage = 1
    },
    updateSchoolName(state, school_name) {
    state.schoolRank.selectedTags.school_name = school_name;
    state.schoolRank.currentPage = 1
    },

    updateCount(state, count){
      state.schoolRank.count = count
      state.schoolRank.totalPages = Math.ceil(state.schoolRank.count / state.schoolRank.pageSize)
    },
    updatePage(state ,page){
      state.schoolRank.currentPage = page
    }
  };
  
  const actions = {
    async fetchSchoolRanks({ commit, state }) {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          alert("账号不存在或登陆失效，请重新登陆！")
          return;
        }
        // 构建查询参数对象
        const params = {
          ...state.schoolRank.selectedTags,
          page:state.schoolRank.currentPage,
        };
        const queryString = new URLSearchParams(params).toString();
        const response = await fetch(`http://www.henangaokao.love/go-v1/school/school-rank?${queryString}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'token': token,
          },
        });
        const data = await response.json();
        if (data.code ==0){
          if (data.data == null){
            commit('updateSchools', data.data);
            commit('updateCount', 0); // 更新总数
          }else{
            commit('updateSchools', data.data.school_infos_with_rank);
            commit('updateCount', data.data.count); // 更新总数
          }
        }else{
          alert(data.msg)
          // state.fetchSchoolRanksErroMsg = data.msg
        }
      } catch (error) {
        alert('请求出错，请稍后重试！')
        // state.fetchSchoolsErrorMsg = '请求出错，请稍后重试！'
      }
    },  
  };
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  };
  