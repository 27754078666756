// store/modules/select.js

const state = {
    selectItem:'',
  };
  
  const getters = {
  };
  
  const mutations = {
    updateSelectItem(state, item) {
        state.selectItem = item;
    },
  };
  
  const actions = {
  };
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  };
  