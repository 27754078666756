// store/modules/userScore.js

const state = {
    tags:{
        locals:[],
        nature_types:[],
        batch_types:[],
        better_types:[],
    },
    fetchTagsErrorMsg:'',
  };
  
  const getters = {
    getTags: state => state.tags
  };
  
  const mutations = {
//     updateSelectedTags(state, { tagType, tagValue }) {
//       state.selectedTags[tagType] = tagValue;
//   },
resetFetchTagsErrorMsg(state) {
  state.fetchTagsErrorMsg = ''
},
    updateTags(state, tags) {
        state.tags = tags;
    }
  };
  
  const actions = {
    async fetchTags({ commit, state }) {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
              alert("账号不存在或登陆失效，请重新登陆！")
              return
            }
            // 发起请求获取标签
            const response = await fetch('http://www.henangaokao.love/go-v1/school/tags',{
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token'),
              },
            });
            const responseData = await response.json();
            if (responseData.code==0){
              commit('updateTags', responseData.data);
            }else{
              alert(responseData.msg)
              // state.fetchTagsErrorMsg = responseData.msg
            }
        } catch (error) {
          alert('请求出错，请稍后重试！')
          // state.fetchSchoolsErrorMsg = '请求出错，请稍后重试！'
        }
    },  
};
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  };
  