// store/modules/school.js

const state = {
  selectedTags: {
    name: '', // 学校名称
    province: '', // 省份
    school_type: '', // 院校类型
    batch_type:'',
    nature_type: '', // 办学类型
    better_type:'',
    recommend_type:0,//筛选等级
    try_type:0,
  },
  selectedTags2:{
    year:'2023',
    major_type: 1,
    min_score:'',
    max_score:'',
  },
  schools: [], // 学校信息
  schoolsWithRank:[],
  schoolsRecommend:[],
  fetchSchoolsErrorMsg:'',
  fetchSchoolsWithScoreErrorMsg:'',
  fetchSchoolsRecommendErrorMsg:'',
  loading: true ,// 初始设置为加载中
  count:0,
  currentPage: 1, // 当前页码
  totalPages:1,
  pageSize: 20, // 每页显示的数量
  maxVisiblePages: 6, // 最大可见页数
};

const getters = {
  getSchools: state => state.schools,
  getSchoolsScore:state => state.schoolsWithRank
};

const mutations = {
  updateSelectedTags(state, { tagType, tagValue }) {
    state.selectedTags[tagType] = tagValue;
    state.currentPage = 1
  },
  resetFetchSchoolsErrorMsg(state) {
    state.fetchSchoolsErrorMsg = ''
  },
  resetFetchSchoolsWithScoreErrorMsg(state) {
    state.fetchSchoolsWithScoreErrorMsg = ''
  },
  resetFetchSchoolsRecommendErrorMsg(state) {
    state.fetchSchoolsRecommendErrorMsg = ''
  },

  resetSelectedTags(state) {
    state.selectedTags.name = '' ;
    state.selectedTags.province = '' ;
    state.selectedTags.school_type = '' ;
    state.selectedTags.batch_type = '' ;
    state.selectedTags.nature_type = '' ;
    state.selectedTags.recommend_type = 0;
    state.selectedTags.better_type = ''

    state.selectedTags2.year = '2023'
    state.selectedTags2.major_type = 1
    state.selectedTags2.min_score = ''
    state.selectedTags2.max_score = ''
    state.currentPage = 1;
  },

  resetSchoolsRecommend(state){
    state.schoolsRecommend = [];
  },

  updateSchools(state, schools) {
    state.schools = schools;
  },

  updateMajorType(state ,major_type){
    state.selectedTags2.major_type = major_type
  },

  updateSchoolsWithRank(state, schools) {
    state.schoolsWithRank = schools;
  },

  updateSchoolsRecommend(state, schools) {
    state.schoolsRecommend = schools;
  },

  updateCount(state, count){
    state.count = count
    state.totalPages = Math.ceil(state.count / state.pageSize)
  },
  updatePage(state ,page){
    state.currentPage = page
  }
};

const actions = {
  async fetchSchools({ commit, state }) {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        alert("账号不存在或登陆失效，请重新登陆！")
        return;
      }

      // 构建查询参数对象
      const params = {
        ...state.selectedTags,
        page:state.currentPage,
      };
      const queryString = new URLSearchParams(params).toString();
      const response = await fetch(`http://www.henangaokao.love/go-v1/school/list?${queryString}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'token': token,
        },
      });
      const data = await response.json();
      if (data.code == 0){
        if (data.data == null){
          commit('updateSchools', data.data);
          commit('updateCount', 0); // 更新总数
        }else{
          commit('updateSchools', data.data.school_infos);
          commit('updateCount', data.data.count); // 更新总数
        }
      }else{
        alert(data.msg)
        // state.fetchSchoolsErrorMsg = data.msg
      }
    } catch (error) {
      alert('请求出错，请稍后重试!')
      // state.fetchSchoolsErrorMsg = '请求出错，请稍后重试！'
    }finally{
      state.loading = false
    }
  },

  async fetchSchoolsWithScore({ commit, state }) {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        alert("账号不存在或登陆失效，请重新登陆！")
        return;
      }
      // 构建查询参数对象
      const params = {
        ...state.selectedTags,
        ...state.selectedTags2,
        page:state.currentPage,
      };
      const queryString = new URLSearchParams(params).toString();
      const response = await fetch(`http://www.henangaokao.love/go-v1/school/score-list?${queryString}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'token': token,
        },
      });
      const data = await response.json();
      if (data.code== 0) {
        if (data.data==null){
          commit('updateSchoolsWithRank', data.data);
          commit('updateCount', 0); // 更新总数
        }else{
          commit('updateSchoolsWithRank', data.data.school_by_score_infos);
          commit('updateCount', data.data.count); // 更新总数
        }
      }else{
        alert(data.msg)
        // state.fetchSchoolsWithScoreErrorMsg = data.msg
      }
    } catch (error) {
      alert('请求出错，请稍后重试！')
      // state.fetchSchoolsErrorMsg = '请求出错，请稍后重试！'
    }
  },

  async fetchSchoolsRecommend({ commit, state }) {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        alert("账号不存在或登陆失效，请重新登陆！")
        return;
      }
      // 构建查询参数对象
      const params = {
        ...state.selectedTags,
        page:state.currentPage,
      };
      const queryString = new URLSearchParams(params).toString();
      const response = await fetch(`http://www.henangaokao.love/go-v1/prediction/recommend?${queryString}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'token': token,
        },
      });
      const data = await response.json();
      if (data.code == 0){
        if (data.data == null){
          commit('updateSchoolsRecommend', data.data);
        }else{
          commit('updateSchoolsRecommend', data.data.recommend_infos);
        }
      }else{
        alert(data.msg)

        // state.fetchSchoolsRecommendErrorMsg = data.msg
      }
    } catch (error) {
      alert('请求出错，请稍后重试！')
      // state.fetchSchoolsErrorMsg = '请求出错，请稍后重试！'
    }finally{
      state.loading = false
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
