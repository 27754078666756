<template>
    <div class="school">
      <div class="school-index">
        <div class="school-head1">
          <div class="head-left">
            <div class="head-left-logo">
              <img class="head-left-logo1" src="../../assets/image/logo/截屏2024-03-21 11.17.22.png" alt>
              <span class="head-left-space"></span>
            </div>
            <!-- <div class="head-left-search">
              <input id="head-left-sarch-content" class="head-left-sarch-content" placeholder="查大学、查专业、搜问答" maxlength="100" autocomplete="off" value>
              <button class="head-sarch-button">搜索</button>
            </div> -->
          </div>
          <div class="head-right" v-if="userScoreInfo.local != '' && userScoreInfo.user_lelve != '' && userScoreInfo.select_class !='' && userScoreInfo.score != ''">
            <span class="head-right-local">{{ userScoreInfo.local }}</span>
            <span class="head-right-type" v-if="userScoreInfo.user_lelve == '2024'">{{ userScoreInfo.select_class }}</span>
            <span class="head-right-type" v-else>{{ displayedClass() }}</span>
            <span class="head-right-score">{{ userScoreInfo.score }}</span>
            <span class="to-hand-cursor" @click="showPopup = true">
              <span>编辑信息</span>
              <i :class="['el-icon-edit']"></i>
            </span>
            <img class="head-right-image" src="../../assets/image/wx_invitepage2.svg" alt="">
          </div>

          <div class="head-right-not-save" v-else>
            <span class="to-hand-cursor" @click="showPopup = true">
              <span>编辑信息</span>
              <i :class="['el-icon-edit']"></i>
            </span>
            <img class="head-right-image" src="../../assets/image/wx_invitepage2.svg" alt="">
          </div>
        </div>
        <div class="school-head2">
          <div class="school-head2-content">

          <div class="to-hand-cursor"  @click="selectOne('school') ;linkToSchool() ; ;" :class="{ 'selected': selectItem === 'school' }">
              院校库
            </div>
            <div class="to-hand-cursor" @click="selectOne('score') ;linkToScore() " :class="{ 'selected': selectItem === 'score' }">
              分数榜
            </div>
            <div class="to-hand-cursor" @click="selectOne('recommend') ; linkToRecommend() " :class="{ 'selected': selectItem === 'recommend' }">
              院校推荐
            </div>
            <div class="to-hand-cursor" @click="selectOne('rank'); linkToSchoolRank()" :class="{ 'selected': selectItem === 'rank' }"> 
              院校排行
            </div>

        </div>
        </div>
        <div class="school-body1">
          <div class="sr-body-head">
            <div class="sr-head-select">
              <div class="sr-head-select-left">
                <input v-model="selectedTags.name" id="score-rank-search" class="score-rank-search" placeholder="输入院校名称" maxlength="150" autocomplete="off" value>
                <div class="sr-year">
                  <div class="sr-year-title">
                    <span class="sr-year-title1">年</span>
                    <span class="sr-year-title2">份:</span>
                  </div>
                  <div class="sr-year-select">
                    <select v-model="selectedTags2.year" name="sr-year-select" id="sr-year-select">
                      <option value="2023">2023</option>
                      <option value="2022">2022</option>
                      <option value="2021">2021</option>
                    </select>
                  </div>
                  <div class="sr-year-title-kemu">
                    <span class="sr-year-title1">科</span>
                    <span class="sr-year-title2">类:</span>
                  </div>
                  <div class="sr-year-class">
                    <div class="sr-year-class1">
                      <input @click="checkMajorType(1)" type="radio" id="option1" name="1" value="1" checked>
                      <label for="1">理科</label>
                    </div>
                    <div class="sr-year-class1">
                      <input @click="checkMajorType(2)" type="radio" id="option2" name="1" value="2">
                      <label for="2">文科</label>
                    </div>
                  </div>
                </div>
                <div class="sr-score-chose">
                  <div class="sr-score-chose-title">
                    <span class="sr-score-chose-title1">分</span>
                    <span class="sr-score-chose-title1">数</span>
                    <span class="sr-score-chose-title1">区</span>
                    <span class="sr-score-chose-title1">间:</span>
                  </div>
                  <div class="sr-score-chose-input">
                    <input v-model="selectedTags2.min_score" type="text" id="sr-score-chose-input1" class="sr-score-chose-input1" placeholder="0"  autocomplete="off" value>
                    <span class="sr-score-chose-input-space">-</span>
                    <input v-model="selectedTags2.max_score" type="text" id="sr-score-chose-input1" class="sr-score-chose-input1" placeholder="750" autocomplete="off" value>
                  </div>
                </div>
              </div>
              <button @click="searchScore()" class="sr-head-select-right">搜索</button>
            </div>
            <div class="body-head-select2">
              <div class="school-type1">
                <div class="school-type">
                  <span>院校地区:</span>
                </div>
                <span class="all-type" @click="handleLocalClick('province','')" :class="{ 'selectedTag': selectedTags.province === '' }">全部</span>
                <div class="type1-content">
                  <!-- <div>{{ selectedTags['province'] }}</div> -->
                  <span v-for="(localTag,index) in this.tags.locals" :key="index" class="chance-content" @click="handleLocalClick('province',localTag)" :class="{ 'selectedTag': selectedTags.province === localTag }">{{ localTag }}</span>
                </div>
              </div>
              <div class="school-type2">
                <span >院校类型:</span>
              </div>
              <span class="all-type2" @click="handleLocalClick('school_type','')" :class="{ 'selectedTag': selectedTags.school_type === '' }">全部</span>
              <div class="type2-content">
                  <span v-for="(schoolTypes,index) in this.tags.school_types" :key="index" class="chance-content" @click="handleLocalClick('school_type',schoolTypes)" :class="{ 'selectedTag': selectedTags.school_type === schoolTypes }">{{ schoolTypes }}</span>
              </div>
              <div class="school-type2">
                <span>办学类型:</span>
              </div>
              <span class="all-type2" @click="handleLocalClick('nature_type','')" :class="{ 'selectedTag': selectedTags.nature_type === '' }">全部</span>
              <div class="type2-content">
                <span v-for="(naturTypes,index) in  this.tags.nature_types" :key="index" class="chance-content" @click="handleLocalClick('nature_type',naturTypes)" :class="{ 'selectedTag': selectedTags.nature_type === naturTypes }">{{ naturTypes }}</span>
              </div>
              <div class="school-type2">
                <span>院校层次:</span>
              </div>
              <span class="all-type2" @click="handleLocalClick('batch_type','')" :class="{ 'selectedTag': selectedTags.batch_type === '' }">全部</span>
              <div class="type2-content">
                <span v-for="(batchTypes,index) in this.tags.batch_types" :key="index" class="chance-content" @click="handleLocalClick('batch_type',batchTypes)" :class="{ 'selectedTag': selectedTags.batch_type === batchTypes }">{{ batchTypes }}</span>
              </div>
              <div class="school-type2">
                <span>院校特色:</span>
              </div>
              <span class="all-type2" @click="handleLocalClick('better_type','')" :class="{ 'selectedTag': selectedTags.better_type === '' }">全部</span>
              <div class="type2-content">
                <span v-for="(betterTypes,index) in  this.tags.better_types" :key="index" class="chance-content" @click="handleLocalClick('better_type',betterTypes)" :class="{ 'selectedTag': selectedTags.better_type === betterTypes }">{{ betterTypes }}</span>
              </div>
          </div>
          </div>
        </div>
        <div class="score-school-body2">
          <div v-if="schoolsWithRank" class="min-score--min-rank">
            <div class="min-score">
              <span>最</span>
              <span>低</span>
              <span>分</span>
            </div>
            <div class="min-rank">
              <span>最</span>
              <span>低</span>
              <span>位</span>
              <span>次</span>
            </div>
          </div>
          <el-table
              :data="schoolsWithRank"
              style="width: 100%">
          </el-table>  
          <div v-for="(school, index) in schoolsWithRank" :key="index" class="school-info" @click="goToBaiDuLink(school.SchoolInfo.link)">
            <div class="school-image">
                <img :src= ' require("../../assets/schoolImages/" + school.SchoolInfo.school_name + ".jpg") ' alt="">
              </div>
              <div class="school-info-cotent-score-rank">
                <div class="school-info-title">
                  <h3 class="school-info-name">
                    <em>{{school.SchoolInfo.school_name}}</em>
                    <span class="school-country">{{school.SchoolInfo.city_name}}{{school.county_name}}</span>
                  </h3>
                </div>
                <div class="school-info-title2">
                  <!-- <span>{{school.SchoolInfo.batch_name}}</span> -->
                  <span>{{school.SchoolInfo.type_name}}</span>
                  <span>{{school.SchoolInfo.tag_name}}</span>
                  <span>{{ school.local_batch_name }}</span>
                  <span>{{ school.zhao_sheng_type }}</span>
                  <span v-if="school.SchoolInfo.f_985 == 1">985</span>
                  <span v-if="school.SchoolInfo.f_211 == 1">211</span>
                  <span v-if="school.SchoolInfo.is_double_first == 1" >双一流</span>
                </div>
              </div>
              <div class="school-min-score">
                {{ school.min_score !== 0 ? school.min_score : '-' }}
              </div>
              
              <div class="school-min-rank">
                {{ school.min_rank !== 0 ? school.min_rank : '-' }}  
              </div>

  
            </div>
        </div>
        <div class="school-page">
        <button class="page-shouye" @click="gotoPage(1)">首页</button>
        <ul class="page-fenye">
          <li @click="prevPage()" title="pre-page" class="pre-page">上一页</li>
          <template v-if="totalPages <= 10">
            <li v-for="page in totalPages" :key="page" @click="gotoPage(page)" title="pre-page" :class="{ 'page-number': true, 'selectedTag': page === currentPage }">{{ page }}</li>
          </template>
          <template v-else>
            <li v-if="currentPage > 4" @click="gotoPage(1)" title="pre-page" class="page-number">1</li>
            <li v-if="currentPage > 5" class="ellipsis">...</li>
            <li v-for="page in visiblePages()" :key="page" @click="gotoPage(page)" title="pre-page" :class="{ 'page-number': true, 'selectedTag': page === currentPage }">{{ page }}</li>
            <li v-if="currentPage < totalPages - 4" class="ellipsis">...</li>
            <li v-if="currentPage < totalPages - 3" @click="gotoPage(totalPages)" title="pre-page" class="page-number">{{ totalPages }}</li>
          </template>
          <li @click="nextPage()" title="pre-page" class="next-page">下一页</li>
        </ul>
        <button class="page-shouye" @click="gotoPage(totalPages)">尾页</button>
      </div>
        <div class="school-foot"></div>

        <div class="modal-wrapper" v-if="showPopup"></div> <!-- 遮罩层 -->
        <div class="save-score-info" v-if="showPopup">
        <div class="save-score-cancel">
          <i @click="showPopup = false ; exitSetUserScore()" class="to-hand-cursor"></i>
        </div>
        <div class="save-score-info-title">
          <span>修</span>
          <span>改</span>
          <span>信</span>
          <span>息</span>
        </div>
        <div class="save-local">
            <div class="save-local-titel">
                高考地区:
            </div>
            <div class="save-level-content">
              <select name="" id="">
                    <option value="2024">河南</option>
                </select>
                <!-- <span>河</span>  
                <span>南</span>   -->
            </div>
        </div>
        <div class="save-local">
            <div class="save-local-titel">
                <span>年</span>
                <span>级:</span>
            </div>
            <div class="save-level-content"> 
                <select v-model="setUserScoreInfo.user_lelve" name="" id="">
                    <option value="2024">高三(2021入学)</option>
                    <option value="2025">高二(2022入学)</option>
                    <option value="2026">高一(2023入学)</option>
                </select>
            </div>
        </div>

        <div class="save-local" v-if="setUserScoreInfo.user_lelve == '2024'">
            <div class="save-local-titel">
              <span>选</span>
              <span>科:</span>
            </div>
            <div class="save-class-type-content">
                <div class="class-type-radio">
                    <input v-model="setUserScoreInfo.class_type" @click="selectClassType('1')" type="radio" name="options" id="option1" value=1>
                    <label for="option1">理科</label>
                </div>
                <div class="class-type-radio">
                    <input v-model="setUserScoreInfo.class_type" @click="selectClassType('2')" type="radio" name="options" id="option2" value=2>
                    <label for="option2">文科</label>
                </div>
            </div>
        </div>

        <div v-else >
          <div class="save-local">
            <div class="save-local-titel">
              <span>首</span>
              <span>选:</span>
            </div>
            <div class="save-class-type-content">
                <div class="class-type-radio">
                    <input v-model="setUserScoreInfo.class_type" @click="selectClassType('1')" type="radio" name="options" id="option1" value=1>
                    <label for="option1">物理</label>
                </div>
                <div class="class-type-radio">
                    <input v-model="setUserScoreInfo.class_type" @click="selectClassType('2')" type="radio" name="options" id="option2" value=2>
                    <label for="option2">历史</label>
                </div>
            </div>
        </div>
        </div>


        <div class="save-local">
            <div class="save-local-titel">
              <span>分</span>
              <span>数:</span>
            </div>
            <div class="save-score-content">
                <input v-model="setUserScoreInfo.score" type="text" name="" id="" placeholder="分数">
            </div>
            <span class="save-score-space"></span>
            <div class="save-local-titel">
              <span>位</span>
              <span>次:</span>
            </div>
            <div class="save-score-content">
                <input v-model="setUserScoreInfo.rank" type="text" name="" id="" placeholder="位次">
            </div>
        </div>

        <div class="save-user-score-button">
          <button @click="saveUserScore()" class="to-hand-cursor-only">提交信息</button>
        </div>
      </div>


      <!-- 错误弹窗 -->
      <div class="error-alert" v-if="fetchSchoolsWithScoreErrorMsg != ''">
        <div class="cancel-an-niu" @click="closeSchoolsWithScoreErrorMsg()">x</div>
        <div class="err-wen-zi">
          {{fetchSchoolsWithScoreErrorMsg}}
        </div>
      </div>
      
      <div class="error-alert" v-if="fetchUserScoreInfoErrorMsg != ''">
        <div class="cancel-an-niu" @click="closeUserScoreInfoErrorMsg()">x</div>
        <div class="err-wen-zi">
          {{fetchUserScoreInfoErrorMsg}}
        </div>
      </div>

      <div class="error-alert" v-if="fetchSaveUserScoreInfoErrorMsg != ''">
        <div class="cancel-an-niu" @click="closeSaveUserScoreInfoErrorMsg()">x</div>
        <div class="err-wen-zi">
          {{fetchSaveUserScoreInfoErrorMsg}}
        </div>
      </div>

      <div class="error-alert" v-if="fetchTagsErrorMsg != ''">
        <div class="cancel-an-niu" @click="closeTagsErrorMsg()">x</div>
        <div class="err-wen-zi">
          {{fetchTagsErrorMsg}}
        </div>
      </div>
      </div>
    </div>
  </template>
  
<script>
import tags from '@/store/modules/tags';
import { mapState, mapActions } from 'vuex';

export default{
  computed: {
    ...mapState('school', ['schoolsWithRank','selectedTags','selectedTags2','pageSize' ,'currentPage','count','totalPages','maxVisiblePages','fetchSchoolsWithScoreErrorMsg']),
    ...mapState('userScoreInfo' ,['userScoreInfo','setUserScoreInfo','fetchUserScoreInfoErrorMsg','fetchSaveUserScoreInfoErrorMsg']),
    ...mapState('tags' ,['tags','fetchTagsErrorMsg']),
    ...mapState('select' ,['selectItem']),
  },
    data() {
      return {
        // schoolName:'' ,
        // schoolYear:'2023',
        // classWen:'',
        // classLi:'',
        // minScore:'',
        // maxScore:'',
        major_type:'0',
        showPopup: false, // 控制弹窗显示状态的变量
      };
    },
    mounted(){
      this.fetchSchoolsWithScore();
      this.fetchUserScoreInfo();
      this.fetchTags()
      this.$store.commit('select/updateSelectItem','score');
      // 监听setUserScoreInfo.score的变化
      this.$store.watch(
        () => this.setUserScoreInfo.score,
        (newScore, oldScore) => {
          // 调用方法处理分数变化
          this.fetchUserScoreToRank();
        }
      );
    },
    methods:{
      ...mapActions('school', ['fetchSchoolsWithScore']),
      ...mapActions('userScoreInfo' ,['fetchUserScoreInfo','fetchSaveUserScoreInfo','fetchUserScoreToRank']),
      ...mapActions('tags' ,['fetchTags']),


    closeUserScoreInfoErrorMsg(){
      this.$store.commit('userScoreInfo/resetFetchUserScoreInfoErrorMsg');
    },
    closeSaveUserScoreInfoErrorMsg(){
      this.$store.commit('userScoreInfo/resetFetchSaveUserScoreInfoErrorMsg');
    },

    closeSchoolsWithScoreErrorMsg(){
      this.$store.commit('school/resetFetchSchoolsWithScoreErrorMsg');
    },

    closeTagsErrorMsg(){
      this.$store.commit('tags/resetFetchTagsErrorMsg');
    },


      displayedClass(){
      if (this.userScoreInfo.select_class == '理科') {
        return '物理';
      } else if (this.userScoreInfo.select_class == '文科') {
        return '历史';
      } else {
        return ''; // 如果有其他情况，可以在这里处理
      }
    },
      exitSetUserScore(){
        this.$store.commit('userScoreInfo/exitSetUserScore');
      },
      goToBaiDuLink(link){
        window.open(link, '_blank');
      },

      selectOne(item) {
        this.$store.commit('select/updateSelectItem',item);
      },
      
      async saveUserScore(){
        this.showPopup = false;
        await this.fetchSaveUserScoreInfo();
        await this.fetchUserScoreInfo();
      },

      selectClassType(type){
        this.$store.commit('userScoreInfo/updateClassType',type);
      }
      ,
      visiblePages() {
      const startPage = Math.max(1, this.currentPage - Math.floor(this.maxVisiblePages / 2));
      const endPage = Math.min(this.totalPages, startPage + this.maxVisiblePages - 1);
      return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
    },
    // 是否有更多页面需要省略
    hasMorePages() {
      return this.totalPages > this.maxVisiblePages && this.visiblePages[this.visiblePages.length - 1] < this.totalPages;
    },

      prevPage() {
      if (this.currentPage > 1) {
        const nowPage = this.currentPage-1
        this.$store.commit('school/updatePage',nowPage);
        this.fetchSchoolsWithScore();
      }
    },
    // 下一页
    nextPage() {
      if (this.currentPage < this.totalPages) {
        const nowPage = this.currentPage+1
        this.$store.commit('school/updatePage',nowPage);
        this.fetchSchoolsWithScore();
      }
    },
    // 跳转到指定页码
    gotoPage(page) {
      this.$store.commit('school/updatePage', page);
      this.fetchSchoolsWithScore();
    },
      
      handleLocalClick(tagName ,tagValue) {
      // 更新筛选条件并获取学校信息
      this.$store.commit('school/updateSelectedTags', { tagType: tagName, tagValue: tagValue });
      this.fetchSchoolsWithScore();
      },
      searchScore(){
        // 更新筛选条件并获取学校信息
        this.$store.commit('school/updatePage' ,1);
        this.fetchSchoolsWithScore();
      },

      checkMajorType(majorType){
        this.$store.commit('school/updateMajorType',majorType );
      },

      linkToSchool(){
          if (this.$route.path !== '/school') {
            this.$store.commit('school/resetSelectedTags');
            this.$router.push('/school'); // '/target-page' 是你要跳转的目标页面的路径
            // window.location.reload();
          }
        },

        linkToScore(){
          if (this.$route.path !== '/score-rank') {
            this.$store.commit('school/resetSelectedTags');
            this.$router.push('/score-rank'); // '/target-page' 是你要跳转的目标页面的路径
          }
        },

        linkToRecommend(){
          if (this.$route.path !== '/recommend') {
            this.$store.commit('school/resetSelectedTags');
            this.$router.push('/recommend'); // '/target-page' 是你要跳转的目标页面的路径
          }
        }
        ,
        linkToSchoolRank(){
          if (this.$route.path !== '/school-rank') {
            this.$store.commit('school/resetSelectedTags');
            this.$router.push('/school-rank'); // '/target-page' 是你要跳转的目标页面的路径
          }
        },
        
    }
}

</script>
  