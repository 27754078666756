import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login/login.vue'
import ScoreRank from '../views/score-rank/scoreRank.vue'
import Recommend from '../views/recommend/recommend.vue'
import SchoolRank from '../views/school-rank/schoolRank.vue'
import ScoreToRank from '../views/scoreToRank/scoreToRank.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/school',
    name: 'school',
    component: () => import('../views/school/school.vue')
  },
  {
    path: '/score-rank',
    name: 'ScoreRank',
    component: ScoreRank
  },
  {
    path: '/recommend',
    name: 'recommend',
    component: Recommend
  },
  {
    path: '/school-rank',
    name: 'schoolRank',
    component: SchoolRank
  },
  {
    path: '/score-to-rank',
    name: 'scoreToRank',
    component: ScoreToRank
  }
]

const router = new VueRouter({
  mode:'history',
  routes
})

// router.beforeEach((to, from, next) => {
//   // 检查是否需要清空筛选条件的标志，比如在路由配置中添加一个meta字段来表示是否需要清空筛选条件
//   if (to.meta.clearFilters) {
//     // 执行清空筛选条件的操作，可以是重置Vuex中的筛选条件状态，或者是将组件中的筛选条件重置为初始值
//     // 例如，如果是通过Vuex存储筛选条件，可以在这里调用一个mutation来重置筛选条件
//     store.commit('resetFilters');
//   }
//   next(); // 继续路由导航
// });

export default router
