import Vue from 'vue'
import Vuex from 'vuex'
import schoolModule from './modules/school.js';
import userScoreModule from './modules/userScore.js';
import tagsModule from './modules/tags.js';
import selectModule from './modules/select.js';
import schoolRankModule from './modules/schoolRank.js';





Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    school: schoolModule,
    userScoreInfo: userScoreModule,
    tags: tagsModule,
    select:selectModule,
    schoolRank:schoolRankModule,
  }
})
