<template>
    <div class="school">
      <div class="school-index">
        <div class="school-head1">
          <div class="head-left">
            <div class="head-left-logo">
              <img class="head-left-logo1" src="../../assets/image/logo/截屏2024-03-21 11.17.22.png" alt>
                <span class="head-left-space"></span>
             </div>
          </div>
          <div class="head-right" v-if="userScoreInfo.local != '' && userScoreInfo.user_lelve != '' && userScoreInfo.select_class !='' && userScoreInfo.score != ''">
            <span class="head-right-local">{{ userScoreInfo.local }}</span>
            <span class="head-right-type" v-if="userScoreInfo.user_lelve == '2024'">{{ userScoreInfo.select_class }}</span>
            <span class="head-right-type" v-else>{{ displayedClass() }}</span>
            <span class="head-right-score">{{ userScoreInfo.score }}</span>
            <span class="to-hand-cursor" @click="showPopup = true">
              <span>编辑信息</span>
              <i :class="['el-icon-edit']"></i>
            </span>
            <img class="head-right-image" src="../../assets/image/wx_invitepage2.svg" alt="">
          </div>

          <div class="head-right-not-save" v-else>
            <span class="to-hand-cursor" @click="showPopup = true">
              <span>编辑信息</span>
              <i :class="['el-icon-edit']"></i>
            </span>
            <img class="head-right-image" src="../../assets/image/wx_invitepage2.svg" alt="">
          </div>
        </div>
        <div class="school-head2">
          <div class="school-head2-content">

          <div class="to-hand-cursor"  @click="selectOne('school') ;linkToSchool() ; ;" :class="{ 'selected': selectItem === 'school' }">
              院校库
            </div>
            <div class="to-hand-cursor" @click="selectOne('score') ;linkToScore() " :class="{ 'selected': selectItem === 'score' }">
              分数榜
            </div>
            <div class="to-hand-cursor" @click="selectOne('recommend');linkToRecommend() " :class="{ 'selected': selectItem === 'recommend' }">
              院校推荐
            </div>
            <div class="to-hand-cursor" @click="selectOne('rank'); linkToSchoolRank()" :class="{ 'selected': selectItem === 'rank' }"> 
              院校排行
            </div>

        </div>
        </div>
        <div class="school-body1" id="school-rank-body">
            <div class="school-rank-select">
                <div class="school-rank-type">
                      <span>排名类型:</span>
                      <span class="ruanke-chance-type" @click="selectRuanKeType(1)" :class="{ 'selected' :  schoolRank.selectedTags.rank_type == 1}">软科</span>
                      <span class="ruanke-chance-type" @click="selectRuanKeType(2)" :class="{ 'selected' :  schoolRank.selectedTags.rank_type == 2}">软科民办</span>
                      <span class="ruanke-chance-type" @click="selectRuanKeType(3)" :class="{ 'selected' :  schoolRank.selectedTags.rank_type == 3}">软科高职</span>
                </div>
                <div class="school-rank-search">
                    <input v-model="schoolRank.selectedTags.name" id="" class="head-left-sarch-content" placeholder="输入院校名称" maxlength="100" autocomplete="off" value>
                    <button class="head-sarch-button" @click="searchSchool(schoolRank.selectedTags.name)">搜索</button>
                </div>
            </div>
        </div>
        <div class="school-body2">
            <div v-for="(school, index) in schoolRank.schoolRankInfos" :key="index" class="school-info" @click="goToBaiDuLink(school.link)">
              <div class="school-rank-number">
                {{school.sort}}
              </div>
              <div class="school-image">
                <img :src= ' require("../../assets/schoolImages/" + school.school_name + ".jpg") ' alt="">
              </div>
              <div class="school-info-cotent">
                <div class="school-info-title">
                  <h3 class="school-info-name">
                    <em>{{school.school_name}}</em>
                    <span class="school-country">{{school.city_name}}{{school.county_name}}</span>
                  </h3>
                </div>
                <div class="school-info-title2">
                  <span>{{school.batch_name}}</span>
                  <span>{{school.type_name}}</span>
                  <span>{{school.tag_name}}</span>
                  <span v-if="school.f_985 == 1">985</span>
                  <span v-if="school.f_211 == 1">211</span>
                  <span v-if="school.is_double_first == 1" >双一流</span>
                </div>
              </div>
              <div>
  
              </div>
  
            </div>
        </div>
        <div class="school-page">
        <button class="page-shouye" @click="gotoPage(1)">首页</button>
        <ul class="page-fenye">
          <li @click="prevPage()" title="pre-page" class="pre-page">上一页</li>
          <template v-if="schoolRank.totalPages <= 10">
            <li v-for="page in schoolRank.totalPages" :key="page" @click="gotoPage(page)" title="pre-page" :class="{ 'page-number': true, 'selectedTag': page === schoolRank.currentPage }">{{ page }}</li>
          </template>
          <template v-else>
            <li v-if="schoolRank.currentPage > 4" @click="gotoPage(1)" title="pre-page" class="page-number">1</li>
            <li v-if="schoolRank.currentPage > 5" class="ellipsis">...</li>
            <li v-for="page in visiblePages()" :key="page" @click="gotoPage(page)" title="pre-page" :class="{ 'page-number': true, 'selectedTag': page === schoolRank.currentPage }">{{ page }}</li>
            <li v-if="schoolRank.currentPage < schoolRank.totalPages - 4" class="ellipsis">...</li>
            <li v-if="schoolRank.currentPage < schoolRank.totalPages - 3" @click="gotoPage(schoolRank.totalPages)" title="pre-page" class="page-number">{{ schoolRank.totalPages }}</li>
          </template>
          <li @click="nextPage()" title="pre-page" class="next-page">下一页</li>
        </ul>
        <button class="page-shouye" @click="gotoPage(schoolRank.totalPages)">尾页</button>
      </div>
        <div class="school-foot"></div>

        <div class="modal-wrapper" v-if="showPopup"></div> <!-- 遮罩层 -->
        <div class="save-score-info" v-if="showPopup">
        <div class="save-score-cancel">
          <i @click="showPopup = false ; exitSetUserScore()" class="to-hand-cursor"></i>
        </div>

        <div class="save-score-info-title">
          <span>修</span>
          <span>改</span>
          <span>信</span>
          <span>息</span>
        </div>
        <div class="save-local">
            <div class="save-local-titel">
                高考地区:
            </div>
            <div class="save-level-content">
              <select name="" id="">
                    <option value="2024">河南</option>
                </select>
                <!-- <span>河</span>  
                <span>南</span>   -->
            </div>
        </div>
        <div class="save-local">
            <div class="save-local-titel">
                <span>年</span>
                <span>级:</span>
            </div>
            <div class="save-level-content"> 
                <select v-model="setUserScoreInfo.user_lelve" name="" id="">
                    <option value="2024">高三(2021入学)</option>
                    <option value="2025">高二(2022入学)</option>
                    <option value="2026">高一(2023入学)</option>
                </select>
            </div>
        </div>

        <div class="save-local" v-if="setUserScoreInfo.user_lelve == '2024'">
            <div class="save-local-titel">
              <span>选</span>
              <span>科:</span>
            </div>
            <div class="save-class-type-content">
                <div class="class-type-radio">
                    <input v-model="setUserScoreInfo.class_type" @click="selectClassType('1')" type="radio" name="options" id="option1" value=1>
                    <label for="option1">理科</label>
                </div>
                <div class="class-type-radio">
                    <input v-model="setUserScoreInfo.class_type" @click="selectClassType('2')" type="radio" name="options" id="option2" value=2>
                    <label for="option2">文科</label>
                </div>
            </div>
        </div>

        <div v-else >
          <div class="save-local">
            <div class="save-local-titel">
              <span>首</span>
              <span>选:</span>
            </div>
            <div class="save-class-type-content">
                <div class="class-type-radio">
                    <input v-model="setUserScoreInfo.class_type" @click="selectClassType('1')" type="radio" name="options" id="option1" value=1>
                    <label for="option1">物理</label>
                </div>
                <div class="class-type-radio">
                    <input v-model="setUserScoreInfo.class_type" @click="selectClassType('2')" type="radio" name="options" id="option2" value=2>
                    <label for="option2">历史</label>
                </div>
            </div>
        </div>
        </div>

        <div class="save-local">
            <div class="save-local-titel">
              <span>分</span>
              <span>数:</span>
            </div>
            <div class="save-score-content">
                <input v-model="setUserScoreInfo.score" type="text" name="" id="" placeholder="分数">
            </div>
            <span class="save-score-space"></span>
            <div class="save-local-titel">
              <span>位</span>
              <span>次:</span>
            </div>
            <div class="save-score-content">
                <input v-model="setUserScoreInfo.rank" type="text" name="" id="" placeholder="位次">
            </div>
        </div>

        <div class="save-user-score-button">
          <button @click="saveUserScore()" class="to-hand-cursor-only">提交信息</button>
        </div>
      </div>


      <!-- 错误弹窗 -->
      <div class="error-alert" v-if="fetchSchoolRanksErroMsg != ''">
        <div class="cancel-an-niu" @click="closeSchoolRanksErroMsg()">x</div>
        <div class="err-wen-zi">
          {{fetchSchoolRanksErroMsg}}
        </div>
      </div>
      
      <div class="error-alert" v-if="fetchUserScoreInfoErrorMsg != ''">
        <div class="cancel-an-niu" @click="closeUserScoreInfoErrorMsg()">x</div>
        <div class="err-wen-zi">
          {{fetchUserScoreInfoErrorMsg}}
        </div>
      </div>

      <div class="error-alert" v-if="fetchSaveUserScoreInfoErrorMsg != ''">
        <div class="cancel-an-niu" @click="closeSaveUserScoreInfoErrorMsg()">x</div>
        <div class="err-wen-zi">
          {{fetchSaveUserScoreInfoErrorMsg}}
        </div>
      </div>
      </div>
    </div>

  </template>
  
<script>
import { mapState, mapActions } from 'vuex';


export default{
  computed: {
    ...mapState('select' ,['selectItem']),
    ...mapState('userScoreInfo' ,['userScoreInfo','setUserScoreInfo' ,'fetchUserScoreInfoErrorMsg','fetchSaveUserScoreInfoErrorMsg']),
    ...mapState('schoolRank' ,['schoolRank','fetchSchoolRanksErroMsg']),
  },

    data() {
      return {
        showPopup: false ,// 控制弹窗显示状态的变量
        userInfo:'',
        tags:'',
      };
    },
    mounted(){
      this.fetchSchoolRanks();
      this.fetchUserScoreInfo();
      this.$store.commit('select/updateSelectItem','rank');
      // 监听setUserScoreInfo.score的变化
      this.$store.watch(
        () => this.setUserScoreInfo.score,
        (newScore, oldScore) => {
          // 调用方法处理分数变化
          this.fetchUserScoreToRank();
        }
      );

    },
    methods:{
      ...mapActions('schoolRank', ['fetchSchoolRanks']),
      ...mapActions('userScoreInfo' ,['fetchUserScoreInfo' ,'fetchSaveUserScoreInfo','fetchUserScoreToRank']),
    
    closeSchoolRanksErroMsg(){
      this.$store.commit('schoolRank/resetFetchSchoolRanksErroMsg');
    },

    closeUserScoreInfoErrorMsg(){
      this.$store.commit('userScoreInfo/resetFetchUserScoreInfoErrorMsg');
    },
    closeSaveUserScoreInfoErrorMsg(){
      this.$store.commit('userScoreInfo/resetFetchSaveUserScoreInfoErrorMsg');
    },


      displayedClass(){
      if (this.userScoreInfo.select_class == '理科') {
        return '物理';
      } else if (this.userScoreInfo.select_class == '文科') {
        return '历史';
      } else {
        return ''; // 如果有其他情况，可以在这里处理
      }
    },
      goToBaiDuLink(link){
        window.open(link, '_blank');
      },

      searchSchool(name){
        this.$store.commit('schoolRank/updateSchoolName',name);
        this.fetchSchoolRanks();
      },
      selectRuanKeType(type){
        this.$store.commit('schoolRank/updateRankType',type);
        this.fetchSchoolRanks();
      },
      exitSetUserScore(){
        this.$store.commit('userScoreInfo/exitSetUserScore');
      },
      selectOne(item) {
        this.$store.commit('select/updateSelectItem',item);
      },
      async saveUserScore(){
        this.showPopup = false;
        await this.fetchSaveUserScoreInfo();
        await this.fetchUserScoreInfo();
      },
      selectClassType(type){
        this.$store.commit('userScoreInfo/updateClassType',type);
      },

      visiblePages() {
      const startPage = Math.max(1, this.schoolRank.currentPage - Math.floor(this.schoolRank.maxVisiblePages / 2));
      const endPage = Math.min(this.schoolRank.totalPages, startPage + this.schoolRank.maxVisiblePages - 1);
      return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
    },
    // 是否有更多页面需要省略
    hasMorePages() {
      return this.schoolRank.totalPages > this.schoolRank.maxVisiblePages && this.schoolRank.visiblePages[this.schoolRank.visiblePages.length - 1] < this.schoolRank.totalPages;
    },
          // 上一页
    prevPage() {
      if (this.schoolRank.currentPage > 1) {
        const nowPage = this.schoolRank.currentPage-1
        this.$store.commit('schoolRank/updatePage', nowPage);
        this.fetchSchoolRanks();
      }
    },
    // 下一页
    nextPage() {
      if (this.schoolRank.currentPage < this.schoolRank.totalPages) {
        const nowPage = this.schoolRank.currentPage+1
        this.$store.commit('schoolRank/updatePage', nowPage);
        this.fetchSchoolRanks();
      }
    },
          // 跳转到指定页码
    gotoPage(page) {
      this.$store.commit('schoolRank/updatePage', page);
      this.fetchSchoolRanks();
    },

    linkToSchool(){
          if (this.$route.path !== '/school') {
            this.$store.commit('school/resetSelectedTags');
            this.$router.push('/school'); // '/target-page' 是你要跳转的目标页面的路径
            // window.location.reload();
          }
        },

        linkToScore(){
          if (this.$route.path !== '/score-rank') {
            this.$store.commit('school/resetSelectedTags');
            this.$router.push('/score-rank'); // '/target-page' 是你要跳转的目标页面的路径
          }
        },

        linkToRecommend(){
          if (this.$route.path !== '/recommend') {
            this.$store.commit('school/resetSelectedTags');
            this.$router.push('/recommend'); // '/target-page' 是你要跳转的目标页面的路径
          }
        }
        ,
        linkToSchoolRank(){
          if (this.$route.path !== '/school-rank') {
            this.$store.commit('school/resetSelectedTags');
            this.$router.push('/school-rank'); // '/target-page' 是你要跳转的目标页面的路径
          }
        },
        
    }
}

</script>
  