import Vue from 'vue'
import Vuex from 'vuex';
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
// import axios from 'axios'
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/index.css';
import './assets/css/school.css';
import './assets/css/scoreRank.css';
import './assets/css/recommendSchool.css'
import './assets/css/schoolRank.css'


Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(Vuex);

// const store = new Vuex.Store({
//   state: {
//     userInfo: null, // 初始化为 null 或者一个空对象
//   },
//   mutations: {
//     setUserInfo(state, userInfo) {
//       state.userInfo = userInfo;
//     },
//   },
//   actions: {
//     fetchUserInfo({ commit }) {
//       // 在这里发起异步请求获取用户信息，并在请求成功后提交 mutation 来更新状态
//       // 你可以根据需要修改这里的实现逻辑
//     },
//   },
// });

// //用户分数
// Vue.prototype.$userInfo = {};
// Vue.prototype.$fetchUserInfo = async function() {
//   try {
//     const token = localStorage.getItem('token');
//     console.log('Token:', localStorage.getItem('token'));
//     if (!token) {
//       console.log('Token is empty!');
//       return
//     }
//     // 发起请求获取个人信息
//     const response = await fetch('http://127.0.0.1:8081/go-v1/my-score',{
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//         'token': localStorage.getItem('token'),
//       },
//     });
//     const responseData = await response.json();
//     console.log('responseData',responseData)

//     // 更新 userInfo 数据
//     Vue.prototype.$userInfo = responseData.data;
//   } catch (error) {
//     alert('haha')
//     console.error('获取个人信息失败:', error);
//   }
// };

// //筛选标签
// Vue.prototype.$tags = {};
// Vue.prototype.$getTags = async function() {
//   try {
//     const token = localStorage.getItem('token');
//     console.log('Token:', localStorage.getItem('token'));
//     if (!token) {
//       console.log('Token is empty!');
//       return
//     }
//     // 发起请求获取标签
//     const response = await fetch('http://127.0.0.1:8081/go-v1/school/tags',{
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//         'token': localStorage.getItem('token'),
//       },
//     });
//     const responseData = await response.json();
//     console.log('tag responseData',responseData)
//     // 更新标签
//     Vue.prototype.$tags = responseData.data;
//   } catch (error) {
//     alert('111')
//     console.error('获取标签失败:', error);
//   }
// };






new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
