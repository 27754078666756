<template>
    <div class="school">
      <div class="school-index">
        <div class="school-head1">
          <div class="head-left">
            <div class="head-left-logo">
              <img class="head-left-logo1" src="../../assets/image/logo/截屏2024-03-21 11.17.22.png" alt>
              <span class="head-left-space"></span>
            </div>
            <!-- <div class="head-left-search">
              <input id="head-left-sarch-content" class="head-left-sarch-content" placeholder="查大学、查专业、搜问答" maxlength="100" autocomplete="off" value>
              <button class="head-sarch-button">搜索</button>
            </div> -->
          </div>
          <div class="head-right" v-if="userScoreInfo.local != '' && userScoreInfo.user_lelve != '' && userScoreInfo.select_class !='' && userScoreInfo.score != ''">
            <span class="head-right-local">{{ userScoreInfo.local }}</span>
            <span class="head-right-type" v-if="userScoreInfo.user_lelve == '2024'">{{ userScoreInfo.select_class }}</span>
            <span class="head-right-type" v-else>{{ displayedClass() }}</span>
            <span class="head-right-score">{{ userScoreInfo.score }}</span>
            <span class="to-hand-cursor" @click="showPopup = true">
              <span>编辑信息</span>
              <i :class="['el-icon-edit']"></i>
            </span>
            <img class="head-right-image" src="../../assets/image/wx_invitepage2.svg" alt="">
          </div>

          <div class="head-right-not-save" v-else>
            <span class="to-hand-cursor" @click="showPopup = true">
              <span>编辑信息</span>
              <i :class="['el-icon-edit']"></i>
            </span>
            <img class="head-right-image" src="../../assets/image/wx_invitepage2.svg" alt="">
          </div>
        </div>
        <div class="school-head2">
          <div class="school-head2-content">
          <div class="to-hand-cursor"  @click="selectOne('school') ;linkToSchool() ; ;" :class="{ 'selected': selectItem === 'school' }">
              院校库
            </div>
            <div class="to-hand-cursor" @click="selectOne('score') ;linkToScore() " :class="{ 'selected': selectItem === 'score' }">
              分数榜
            </div>
            <div class="to-hand-cursor" @click="selectOne('recommend');linkToRecommend() " :class="{ 'selected': selectItem === 'recommend' }">
              院校推荐
            </div>
            <div class="to-hand-cursor" @click="selectOne('rank'); linkToSchoolRank()" :class="{ 'selected': selectItem === 'rank' }"> 
              院校排行
            </div>

        </div>
        </div>
        <div class="school-body1">
          <div class="body-head-recommend">
          <div class="body-head-select">
            <input v-model="schoolName" id="body-head-search" class="head-left-sarch-content" placeholder="输入院校名称" maxlength="100" autocomplete="off" value>
            <button @click="handleLocalClick('name',schoolName)" class="head-sarch-button">搜索</button>
          </div>
          <div class="body-head-select2">
              <div class="school-type1">
                <div class="school-type">
                  <span>院校地区:</span>
                </div>
                <span class="all-type" @click="handleLocalClick('province','')" :class="{ 'selectedTag': selectedTags.province === '' }">全部</span>
                <div class="type1-content">
                  <!-- <div>{{ selectedTags['province'] }}</div> -->
                  <span v-for="(localTag,index) in this.tags.locals" :key="index" class="chance-content" @click="handleLocalClick('province',localTag)" :class="{ 'selectedTag': selectedTags.province === localTag }">{{ localTag }}</span>
                </div>
              </div>
              <div class="school-type2">
                <span >院校类型:</span>
              </div>
              <span class="all-type2" @click="handleLocalClick('school_type','')" :class="{ 'selectedTag': selectedTags.school_type === '' }">全部</span>
              <div class="type2-content">
                  <span v-for="(schoolTypes,index) in this.tags.school_types" :key="index" class="chance-content" @click="handleLocalClick('school_type',schoolTypes)" :class="{ 'selectedTag': selectedTags.school_type === schoolTypes }">{{ schoolTypes }}</span>
              </div>
              <div class="school-type2">
                <span>办学类型:</span>
              </div>
              <span class="all-type2" @click="handleLocalClick('nature_type','')" :class="{ 'selectedTag': selectedTags.nature_type === '' }">全部</span>
              <div class="type2-content">
                <span v-for="(naturTypes,index) in  this.tags.nature_types" :key="index" class="chance-content" @click="handleLocalClick('nature_type',naturTypes)" :class="{ 'selectedTag': selectedTags.nature_type === naturTypes }">{{ naturTypes }}</span>
              </div>
              <div class="school-type2">
                <span>院校层次:</span>
              </div>
              <span class="all-type2" @click="handleLocalClick('batch_type','')" :class="{ 'selectedTag': selectedTags.batch_type === '' }">全部</span>
              <div class="type2-content">
                <span v-for="(batchTypes,index) in this.tags.batch_types" :key="index" class="chance-content" @click="handleLocalClick('batch_type',batchTypes)" :class="{ 'selectedTag': selectedTags.batch_type === batchTypes }">{{ batchTypes }}</span>
              </div>
              <div class="school-type2">
                <span>院校特色:</span>
              </div>
              <span class="all-type2" @click="handleLocalClick('better_type','')" :class="{ 'selectedTag': selectedTags.better_type === '' }">全部</span>
              <div class="type2-content">
                <span v-for="(betterTypes,index) in  this.tags.better_types" :key="index" class="chance-content" @click="handleLocalClick('better_type',betterTypes)" :class="{ 'selectedTag': selectedTags.better_type === betterTypes }">{{ betterTypes }}</span>
              </div>
              <div class="school-type2">
                <span>筛选等级:</span>
              </div>
              <span class="all-type2" @click="handleLocalClick('recommend_type',0)" :class="{ 'selectedTag': selectedTags.recommend_type === 0 }">全部</span>
              <div class="type2-content">
                <span class="chance-content" @click="handleLocalClick('recommend_type',1)" :class="{ 'selectedTag': selectedTags.recommend_type === 1}">初级筛选</span>
                <span class="chance-content" @click="handleLocalClick('recommend_type',2)" :class="{ 'selectedTag': selectedTags.recommend_type === 2}">中级筛选</span>
                <span class="chance-content" @click="handleLocalClick('recommend_type',3)" :class="{ 'selectedTag': selectedTags.recommend_type === 3}">高级筛选</span>
                <span class="chance-content" @click="handleLocalClick('recommend_type',4)" :class="{ 'selectedTag': selectedTags.recommend_type === 4}">顶级筛选</span>
              </div>

              <div class="school-type2">
                <span>推荐类型:</span>
              </div>
              <span class="all-type2" @click="handleLocalClick('try_type',0)" :class="{ 'selectedTag': selectedTags.try_type === 0 }">全部</span>
              <div class="type2-content">
                <span class="chance-content" @click="handleLocalClick('try_type',1)" :class="{ 'selectedTag': selectedTags.try_type === 1}">锦上添花</span>
                <span class="chance-content" @click="handleLocalClick('try_type',2)" :class="{ 'selectedTag': selectedTags.try_type === 2}">逆风翻盘</span>
                <span class="chance-content" @click="handleLocalClick('try_type',3)" :class="{ 'selectedTag': selectedTags.try_type === 3}">极端激进</span>
              </div>
          </div>
        </div>
        </div>
        <div class="school-body2-recommend">
          <el-table
          v-if="!loading"
          :data="this.schoolsRecommend"
          style="width: 100%">
          <!-- el-table 的列定义 -->
          </el-table>
          <div v-else class="loading-indicator">大数据统计中...</div>
          <div v-if="this.schoolsRecommend && this.schoolsRecommend.length > 0" class="recommend-school-title">
            <div class="recommend-school-title1">历年录取分数/位次</div>
            <div class="recommend-school-title2">推荐性价比</div>
          </div>
          <div v-for="(school, index) in this.schoolsRecommend" :key="index" class="school-info" @click="goToBaiDuLink(school.SchoolInfo.link)" >
              <div class="school-image">
                <img :src= ' require("../../assets/schoolImages/" + school.SchoolInfo.school_name + ".jpg") ' alt="">
              </div>
              <div class="school-info-cotent">
                <div class="school-info-title">
                  <h3 class="school-info-name">
                    <em>{{ school.SchoolInfo.school_name }}</em>
                    <span class="school-country">{{school.SchoolInfo.city_name}}</span>
                  </h3>
                </div>
                <div class="school-info-title2">
                  <span>{{school.SchoolInfo.batch_name}}</span>
                  <span>{{school.SchoolInfo.type_name}}</span>
                  <span v-if="school.SchoolInfo.tag_name">{{ school.SchoolInfo.tag_name }}</span>
                  <span v-if="school.SchoolInfo.in_batch_name">{{ school.SchoolInfo.in_batch_name }}</span>
                  <span v-if="school.SchoolInfo.f_985 == 1">985</span>
                  <span v-if="school.SchoolInfo.f_211 == 1">211</span>
                  <span v-if="school.SchoolInfo.is_double_first == 1" >双一流</span>
                </div>
              </div>
              <div class="recommend-school-history">
                    <div class="recommend-school-history-content">
                        <span>{{ school.history_infos[0].history_year }}</span>
                    </div>
                    <div class="recommend-school-history-content">
                        <span>{{ school.history_infos[1].history_year }}</span>
                    </div>
                    <div class="recommend-school-history-content-last">
                        <span>{{ school.history_infos[2].history_year }}</span>
                    </div>
                    <!-- <div class="recommend-school-history-title2">
                        <span>最低分/位次 :</span>
                    </div> -->
                    <div class="recommend-school-history-content">
                        <span>{{ school.history_infos[0].history_min_score !== 0 ? school.history_infos[0].history_min_score : '-' }} / {{school.history_infos[0].history_min_ranks !==0 ? school.history_infos[0].history_min_ranks : '-'}}</span>
                    </div>
                    <div class="recommend-school-history-content">
                        <span>{{ school.history_infos[1].history_min_score !== 0 ? school.history_infos[1].history_min_score:'-' }} / {{school.history_infos[1].history_min_ranks !== 0 ? school.history_infos[1].history_min_ranks:'-'}}</span>
                    </div>
                    <div class="recommend-school-history-content-last">
                        <span>{{ school.history_infos[2].history_min_score !==0? school.history_infos[2].history_min_score:'-'}} / {{school.history_infos[2].history_min_ranks !==0 ? school.history_infos[2].history_min_ranks:'-'}}</span>
                    </div>
              </div>
              <div class="recommend-persent">
                <div class="recommend-persent1">
                    {{school.recommend_type}}
                </div>
              </div>
            </div>
        </div>
        <!-- <div class="school-page">
          <button class="page-shouye">首页</button>
          <ul class="page-fenye">
            <li title="pre-page" class="pre-page">上一页</li>
            <li title="pre-page" class="page-number">1</li>
            <li title="pre-page" class="page-number">2</li>
            <li title="pre-page" class="page-number">3</li>
            <li title="pre-page" class="next-page">下一页</li>
          </ul>
          <button class="page-shouye">尾页</button>
        </div> -->
        <div class="school-foot"></div>

        <div class="modal-wrapper" v-if="showPopup"></div> <!-- 遮罩层 -->
        <div class="save-score-info" v-if="showPopup">
        <div class="save-score-cancel">
          <i @click="showPopup = false ; exitSetUserScore()" class="to-hand-cursor"></i>
        </div>
        <div class="save-score-info-title">
          <span>修</span>
          <span>改</span>
          <span>信</span>
          <span>息</span>
        </div>
        <div class="save-local">
            <div class="save-local-titel">
                高考地区:
            </div>
            <div class="save-level-content">
              <select name="" id="">
                    <option value="2024">河南</option>
                </select>
                <!-- <span>河</span>  
                <span>南</span>   -->
            </div>
        </div>
        <div class="save-local">
            <div class="save-local-titel">
                <span>年</span>
                <span>级:</span>
            </div>
            <div class="save-level-content"> 
                <select v-model="setUserScoreInfo.user_lelve" name="" id="">
                    <option value="2024">高三(2021入学)</option>
                    <option value="2025">高二(2022入学)</option>
                    <option value="2026">高一(2023入学)</option>
                </select>
            </div>
        </div>

        <div class="save-local" v-if="setUserScoreInfo.user_lelve == '2024'">
            <div class="save-local-titel">
              <span>选</span>
              <span>科:</span>
            </div>
            <div class="save-class-type-content">
                <div class="class-type-radio">
                    <input v-model="setUserScoreInfo.class_type" @click="selectClassType('1')" type="radio" name="options" id="option1" value=1>
                    <label for="option1">理科</label>
                </div>
                <div class="class-type-radio">
                    <input v-model="setUserScoreInfo.class_type" @click="selectClassType('2')" type="radio" name="options" id="option2" value=2>
                    <label for="option2">文科</label>
                </div>
            </div>
        </div>

        <div v-else >
          <div class="save-local">
            <div class="save-local-titel">
              <span>首</span>
              <span>选:</span>
            </div>
            <div class="save-class-type-content">
                <div class="class-type-radio">
                    <input v-model="setUserScoreInfo.class_type" @click="selectClassType('1')" type="radio" name="options" id="option1" value=1>
                    <label for="option1">物理</label>
                </div>
                <div class="class-type-radio">
                    <input v-model="setUserScoreInfo.class_type" @click="selectClassType('2')" type="radio" name="options" id="option2" value=2>
                    <label for="option2">历史</label>
                </div>
            </div>
        </div>
        </div>

        <div class="save-local">
            <div class="save-local-titel">
              <span>分</span>
              <span>数:</span>
            </div>
            <div class="save-score-content">
                <input v-model="setUserScoreInfo.score" type="text" name="" id="" placeholder="分数">
            </div>
            <span class="save-score-space"></span>
            <div class="save-local-titel">
              <span>位</span>
              <span>次:</span>
            </div>
            <div class="save-score-content">
                <input v-model="setUserScoreInfo.rank" type="text" name="" id="" placeholder="位次">
            </div>
        </div>

        <div class="save-user-score-button">
          <button @click="saveUserScore()" class="to-hand-cursor-only">提交信息</button>
        </div>
      </div>

      <!-- 错误弹窗 -->
      <div class="error-alert" v-if="fetchSchoolsRecommendErrorMsg != ''">
        <div class="cancel-an-niu" @click="closeSchoolsRecommendErrorMsg()">x</div>
        <div class="err-wen-zi">
          {{fetchSchoolsRecommendErrorMsg}}
        </div>
      </div>
      
      <div class="error-alert" v-if="fetchUserScoreInfoErrorMsg != ''">
        <div class="cancel-an-niu" @click="closeUserScoreInfoErrorMsg()">x</div>
        <div class="err-wen-zi">
          {{fetchUserScoreInfoErrorMsg}}
        </div>
      </div>

      <div class="error-alert" v-if="fetchSaveUserScoreInfoErrorMsg != ''">
        <div class="cancel-an-niu" @click="closeSaveUserScoreInfoErrorMsg()">x</div>
        <div class="err-wen-zi">
          {{fetchSaveUserScoreInfoErrorMsg}}
        </div>
      </div>

      <div class="error-alert" v-if="fetchTagsErrorMsg != ''">
        <div class="cancel-an-niu" @click="closeTagsErrorMsg()">x</div>
        <div class="err-wen-zi">
          {{fetchTagsErrorMsg}}
        </div>
      </div>

      </div>
    </div>
  </template>
  
  <script>
import { mapState, mapActions } from 'vuex';

export default{
  computed: {
    ...mapState('school', ['schoolsRecommend' , 'selectedTags','loading','fetchSchoolsRecommendErrorMsg']),
    ...mapState('userScoreInfo' ,['userScoreInfo','setUserScoreInfo' ,'fetchUserScoreInfoErrorMsg','fetchSaveUserScoreInfoErrorMsg']),
    ...mapState('tags' ,['tags' ,'fetchTagsErrorMsg']),
    ...mapState('select' ,['selectItem']),
    // locals() {
    //   return this.$store.state.school.tags.locals;
    // }
  },
    data() {
      return {
        schoolName:'',
        showPopup: false, // 控制弹窗显示状态的变量
      };
    },
    mounted(){
      this.fetchSchoolsRecommend();
      this.fetchUserScoreInfo();
      this.fetchTags()
      this.$store.commit('select/updateSelectItem','recommend');
      // 监听setUserScoreInfo.score的变化
      this.$store.watch(
        () => this.setUserScoreInfo.score,
        (newScore, oldScore) => {
          // 调用方法处理分数变化
          this.fetchUserScoreToRank();
        }
      );
    },
    methods:{
      ...mapActions('school', ['fetchSchoolsRecommend']),
      ...mapActions('userScoreInfo' ,['fetchUserScoreInfo' ,'fetchSaveUserScoreInfo','fetchUserScoreToRank']),
      ...mapActions('tags' ,['fetchTags']),

    closeUserScoreInfoErrorMsg(){
      this.$store.commit('userScoreInfo/resetFetchUserScoreInfoErrorMsg');
    },
    closeSaveUserScoreInfoErrorMsg(){
      this.$store.commit('userScoreInfo/resetFetchSaveUserScoreInfoErrorMsg');
    },

    closeSchoolsRecommendErrorMsg(){
      this.$store.commit('school/resetFetchSchoolsRecommendErrorMsg');
    },

    closeTagsErrorMsg(){
      this.$store.commit('tags/resetFetchTagsErrorMsg');
    },

      displayedClass(){
      if (this.userScoreInfo.select_class == '理科') {
        return '物理';
      } else if (this.userScoreInfo.select_class == '文科') {
        return '历史';
      } else {
        return ''; // 如果有其他情况，可以在这里处理
      }
    },
      goToBaiDuLink(link){
        window.open(link, '_blank');
      },

      selectOne(item) {
        this.$store.commit('select/updateSelectItem',item);
      },

      exitSetUserScore(){
        this.$store.commit('userScoreInfo/exitSetUserScore');
      },
      async saveUserScore(){
        this.showPopup = false;
        await this.fetchSaveUserScoreInfo();
        await this.fetchUserScoreInfo();
        await this.fetchSchoolsRecommend();
      },
      selectClassType(type){
        this.$store.commit('userScoreInfo/updateClassType',type);
      }
      ,

      handleLocalClick(tagName ,tagValue) {
      // 更新筛选条件并获取学校信息
      this.$store.commit('school/updateSelectedTags', { tagType: tagName, tagValue: tagValue });
      this.fetchSchoolsRecommend();
    },

    linkToSchool(){
          if (this.$route.path !== '/school') {
            this.$store.commit('school/resetSelectedTags');
            this.$router.push('/school'); // '/target-page' 是你要跳转的目标页面的路径
            // window.location.reload();
          }
        },

        linkToScore(){
          if (this.$route.path !== '/score-rank') {
            this.$store.commit('school/resetSelectedTags');
            this.$router.push('/score-rank'); // '/target-page' 是你要跳转的目标页面的路径
          }
        },

        linkToRecommend(){
          if (this.$route.path !== '/recommend') {
            this.$store.commit('school/resetSelectedTags');
            this.$router.push('/recommend'); // '/target-page' 是你要跳转的目标页面的路径
          }
        }
        ,
        linkToSchoolRank(){
          if (this.$route.path !== '/school-rank') {
            this.$store.commit('school/resetSelectedTags');
            this.$router.push('/school-rank'); // '/target-page' 是你要跳转的目标页面的路径
          }
        },
        
    }
}

  </script>
  